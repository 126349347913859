"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoursesTogglePublishedStatus = exports.CoursesTogglePublishedStatusOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.CoursesTogglePublishedStatusOperationId = 'CoursesTogglePublishedStatus';
/**
 * Toggle the published status of a course
 *
 * @path /courses/:courseId/toggle-published
 * @generated
 */
exports.CoursesTogglePublishedStatus = (0, ClientController_1.ClientControllerFactory)({
    path: '/courses/:courseId/toggle-published',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
