"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotesGetNotes = exports.NotesGetNotesOperationId = void 0;
const ClientController_1 = require("../../../ClientController");
exports.NotesGetNotesOperationId = 'NotesGetNotes';
/**
 * Get notes
 *
 * @path /notes
 * @generated
 */
exports.NotesGetNotes = (0, ClientController_1.ClientControllerFactory)({
    path: '/notes',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 500],
});
