"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.OccurrencesPostOccurrences = exports.OccurrencesPostOccurrencesOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.OccurrencesPostOccurrencesOperationId = 'OccurrencesPostOccurrences';
/**
 * @path /courses/:courseId/occurrences
 * @generated
 */
exports.OccurrencesPostOccurrences = (0, ClientController_1.ClientControllerFactory)({
    path: '/courses/:courseId/occurrences',
    method: 'POST',
    definedStatusCodes: [200, 400, 401, 500],
});
