"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserGroupsList = exports.UserGroupsListOperationId = void 0;
const ClientController_1 = require("../../../ClientController");
exports.UserGroupsListOperationId = 'UserGroupsList';
/**
 * Lists all user groups associated with the requester's educator
 *
 * @path /user_groups
 * @generated
 */
exports.UserGroupsList = (0, ClientController_1.ClientControllerFactory)({
    path: '/user_groups',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 500],
});
