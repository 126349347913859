"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersList = exports.UsersListOperationId = void 0;
const ClientController_1 = require("../../../ClientController");
exports.UsersListOperationId = 'UsersList';
/**
 * List's all the Users in the requesters educator
 *
 * @path /users
 * @generated
 */
exports.UsersList = (0, ClientController_1.ClientControllerFactory)({
    path: '/users',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 500],
});
