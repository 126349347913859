"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForumsListPosts = exports.ForumsListPostsOperationId = void 0;
const ClientController_1 = require("../../../../../ClientController");
exports.ForumsListPostsOperationId = 'ForumsListPosts';
/**
 * @path /forums/:forumId/posts
 * @generated
 */
exports.ForumsListPosts = (0, ClientController_1.ClientControllerFactory)({
    path: '/forums/:forumId/posts',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 500],
});
