"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MediaGetUploadUrl = exports.MediaGetUploadUrlOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.MediaGetUploadUrlOperationId = 'MediaGetUploadUrl';
/**
 * Get signed URLs to upload a piece of media
 *
 * @path /media/get-upload-url
 * @generated
 */
exports.MediaGetUploadUrl = (0, ClientController_1.ClientControllerFactory)({
    path: '/media/get-upload-url',
    method: 'POST',
    definedStatusCodes: [200, 400, 401, 500],
});
