"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.UploadsTempFileUrl = exports.UploadsTempFileUrlOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.UploadsTempFileUrlOperationId = 'UploadsTempFileUrl';
/**
 * Get a URL to upload a temporary file
 *
 * @path /uploads/get-tmp-url
 * @generated
 */
exports.UploadsTempFileUrl = (0, ClientController_1.ClientControllerFactory)({
    path: '/uploads/get-tmp-url',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 500],
});
