"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoursesListDates = exports.CoursesListDatesOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.CoursesListDatesOperationId = 'CoursesListDates';
/**
 * List upcoming or past courses for an Educator
 *
 * @path /courses/dates
 * @generated
 */
exports.CoursesListDates = (0, ClientController_1.ClientControllerFactory)({
    path: '/courses/dates',
    method: 'GET',
    definedStatusCodes: [200, 400, 500],
});
