"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotesCreateNote = exports.NotesCreateNoteOperationId = void 0;
const ClientController_1 = require("../../../ClientController");
exports.NotesCreateNoteOperationId = 'NotesCreateNote';
/**
 * Create a note
 *
 * @path /notes
 * @generated
 */
exports.NotesCreateNote = (0, ClientController_1.ClientControllerFactory)({
    path: '/notes',
    method: 'POST',
    definedStatusCodes: [200, 400, 401, 500],
});
