"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotesDeleteNote = exports.NotesDeleteNoteOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.NotesDeleteNoteOperationId = 'NotesDeleteNote';
/**
 * Delete a note
 *
 * @path /notes/:noteId
 * @generated
 */
exports.NotesDeleteNote = (0, ClientController_1.ClientControllerFactory)({
    path: '/notes/:noteId',
    method: 'DELETE',
    definedStatusCodes: [204, 400, 401, 404, 500],
});
