"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotesDeleteAttachment = exports.NotesDeleteAttachmentOperationId = void 0;
const ClientController_1 = require("../../../../../../ClientController");
exports.NotesDeleteAttachmentOperationId = 'NotesDeleteAttachment';
/**
 * Delete an attachment from a note
 *
 * @path /notes/:noteId/attachments/:fileKey
 * @generated
 */
exports.NotesDeleteAttachment = (0, ClientController_1.ClientControllerFactory)({
    path: '/notes/:noteId/attachments/:fileKey',
    method: 'DELETE',
    definedStatusCodes: [200, 400, 401, 404, 500],
});
