"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotesFileUploadUrl = exports.NotesFileUploadUrlOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.NotesFileUploadUrlOperationId = 'NotesFileUploadUrl';
/**
 * Get a URL to upload a file for a note
 *
 * @path /notes/file-upload-url
 * @generated
 */
exports.NotesFileUploadUrl = (0, ClientController_1.ClientControllerFactory)({
    path: '/notes/file-upload-url',
    method: 'GET',
    definedStatusCodes: [200, 400, 401, 500],
});
