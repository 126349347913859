"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CoursesQueryCourses = exports.CoursesQueryCoursesOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.CoursesQueryCoursesOperationId = 'CoursesQueryCourses';
/**
 * Query endpoint for Admins to get courses!!
 *
 * @path /courses/query
 * @generated
 */
exports.CoursesQueryCourses = (0, ClientController_1.ClientControllerFactory)({
    path: '/courses/query',
    method: 'POST',
    definedStatusCodes: [200, 400, 500],
});
