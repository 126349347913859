"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotesUpdateNote = exports.NotesUpdateNoteOperationId = void 0;
const ClientController_1 = require("../../../../ClientController");
exports.NotesUpdateNoteOperationId = 'NotesUpdateNote';
/**
 * Update a note
 *
 * @path /notes/:noteId
 * @generated
 */
exports.NotesUpdateNote = (0, ClientController_1.ClientControllerFactory)({
    path: '/notes/:noteId',
    method: 'POST',
    definedStatusCodes: [200, 400, 401, 404, 500],
});
